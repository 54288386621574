/**
 * @class AuthService
 * @description This class contains all the service related to auth
 */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseService } from './base.service';
import {
    GENERATE_OTP_API,
    VERIFY_OTP_API,
    LOGIN_API,
    LOGOUT_API,
    REFRESH_TOKEN_API,
} from '../../shared/constants/api.constant';
import { STORAGE_KEY } from 'src/app/shared/enums/storage-keys.enum';
import { LocalStorageService } from './localstorage.service';

@Injectable({
    providedIn: 'root',
})
export class AuthService extends BaseService {

    constructor(
        httpClient: HttpClient,
        private localStorageService: LocalStorageService
    ) {
        super(httpClient);
    }

    /**
     * @function generateOtp
     * @param params
     * @description This function generates OTP
     */
    generateOtp(params: any): Observable<any> {
        return this.get(params, GENERATE_OTP_API);
    }

    /**
     * @function verifyOtp
     * @param params
     * @description This function verifies OTP
     */
    verifyOtp(params: any): Observable<any> {
        return this.get(params, VERIFY_OTP_API);
    }

    /**
     * @function login
     * @param data
     * @description This function is for login
     */
    login(data): Observable<any> {
        return this.post(data, null, LOGIN_API);
    }

    /**
     * @function refreshToken
     * @param data
     * @description This function is for refreshing token
     */
    refreshToken(): Observable<any> {
        return this.post(null, null, REFRESH_TOKEN_API);
    }

    /**
     * @function isLoggedIn
     * @description This function is to check if user is logged in
     */
    isLoggedIn(): boolean {
        return this.localStorageService.getSavedState(STORAGE_KEY.ACCESS_TOKEN_LTM) && this.localStorageService.getSavedState(STORAGE_KEY.REFRESH_TOKEN_RTM);
    }

    /**
     * @function logout
     * @param data
     * @description This function is for logout
     */
    logout(params): Observable<any> {
        return this.post(params, null, LOGOUT_API);
    }

}
