import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { catchError, Observable, switchMap, throwError } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocalStorageService } from '../services/localstorage.service';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { STORAGE_KEY } from 'src/app/shared/enums/storage-keys.enum';
import {
  AUTH_PATH,
  HOME_PATH,
} from 'src/app/shared/constants/routes.constants';

@Injectable({
  providedIn: 'root',
})
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private snackbar: MatSnackBar,
    private localStorageService: LocalStorageService,
    private router: Router,
    private auth: AuthService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        console.log(error);

        //access_token expired
        if (
          error.status === 401 &&
          error.error?.error_message === 'Invalid LTM!'
        ) {
          return this.auth.refreshToken().pipe(
            switchMap((response) => {
              if (response.success) {
                const {
                  data: { access_token, refresh_token },
                } = response;
                this.localStorageService.setSavedState(
                  { access_token },
                  STORAGE_KEY.ACCESS_TOKEN_LTM
                );
                this.localStorageService.setSavedState(
                  { refresh_token },
                  STORAGE_KEY.REFRESH_TOKEN_RTM
                );
                request = request.clone({
                  headers: request.headers.set(
                    'Authorization',
                    `Bearer ${
                      this.localStorageService.getSavedState(
                        STORAGE_KEY.ACCESS_TOKEN_LTM
                      )?.access_token
                    }`
                  ),
                });

                return next.handle(request);
              }
              return throwError(() => new Error(error.error));
            })
          );
        }
        //refresh_token expired
        else if (
          error.status === 401 &&
          error.error?.error_message === 'Invalid RTM!'
        ) {
          localStorage.clear();
          this.router.navigate([`/${AUTH_PATH}`]);
        }
        //No project found redirect
        else if (
          error.status === 500 &&
          error.error?.error_message === 'No projects found'
        ) {
          this.router.navigate([`/${HOME_PATH}`], {
            queryParams: {
              new_user: true,
            },
          });
        }
        //No project found redirect
        else if (
          error.error?.error_message ===
          'Feed feature is disabled in this community'
        ) {
          console.log(error.error?.error_message);
        } else
          this.snackbar.open(
            error.status === 0 ? error.message : error.error?.error_message,
            'Close',
            {
              duration: 5000,
              panelClass: ['error-alert-snackbar'],
              horizontalPosition: 'right',
            }
          );

        return throwError(() => error);
      })
    );
  }
}
