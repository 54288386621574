import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
import { LocalStorageService } from 'src/app/core/services/localstorage.service';
import { SDKService } from 'src/app/core/services/sdk.service';
import { MemberService } from 'src/app/pages/community/services/member.service';
import { STORAGE_KEY } from '../../enums/storage-keys.enum';

@Component({
  selector: 'app-member-permission-dialog',
  templateUrl: './member-permission-dialog.component.html',
  styleUrls: ['./member-permission-dialog.component.scss'],
})
export class MemberPermissionDialogComponent implements OnInit {
  project: any;
  private destroy$$ = new Subject();
  community: any;
  rights: any = [];
  rightsParams: any;
  customTitle: any;
  changeValue = false;
  constructor(
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private memberService: MemberService,
    private localStorageService: LocalStorageService,
    private sDKService: SDKService,
  ) {}

  ngOnInit(): void {
    this.getPermission();
    this.sDKService.currentProject$$
      .pipe(takeUntil(this.destroy$$))
      .subscribe((res) => {
        this.project = res;
      });
    this.community = this.localStorageService.getSavedState(
      STORAGE_KEY.OPENED_PROJECT,
    );
  }
  close() {
    this.dialogRef.close();
  }

  goToPage(memberPage: string) {
    this.dialogRef.close(memberPage);
  }

  getPermission() {
    const params = {
      user_id: this.data?.user_reported?.id || this.data?.reported_by_user?.id,
      is_cm: false,
    };
    this.memberService
      .permissions(params)
      .pipe(takeUntil(this.destroy$$))
      .subscribe((res) => {
        this.customTitle = res.data.member.custom_title;
        res.data.rights.forEach((data) => {
          this.rights.push(data);
        });
      });
  }

  changeRights(rights, e) {
    this.changeValue = true;
    this.rights.forEach((ele) => {
      if (ele.title === rights.title) {
        ele.is_selected = e.checked;
      }
    });
  }
  enterCustomTitle(e) {
    this.changeValue = true;
  }

  onPermissionUpdate() {
    if (!this.changeValue) return;
    this.rightsParams = {
      uuid: this.data?.user_reported?.uuid || this.data?.reported_by_user?.uuid,
      is_cm: false,
      rights: this.rights,
      custom_title: this.customTitle,
    };

    if (this.rightsParams) {
      this.memberService
        .permissionUpdate(this.rightsParams)
        .pipe(takeUntil(this.destroy$$))
        .subscribe((_) => {
          this.dialogRef.close('member');
        });
    } else {
      this.dialogRef.close('');
    }
  }

  ngOnDestroy(): void {
    this.destroy$$.next(null);
    this.destroy$$.complete();
  }
}
