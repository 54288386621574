<div mat-dialog-title class="title w-100 d-flex justify-content-between align-items-center">
    <div></div>
    <div>Create New App</div>
    <div mat-dialog-close class="cursor-pointer"><img src="assets/images/svg/cross.svg"></div>
</div>

<div class="content">
    Enter app name and upload app icon that you wish to integrate with LikeMinds. You can change the details later from
    the App information page.
</div>

<form class="form" (submit)="createProject()">
    <div class="d-flex">
        <!-- <div class="img-upload align-self-center">
            <input type="file">
            <div class="cover d-flex justify-content-center align-items-center">
                <img src="assets/images/svg/plus.svg" alt="">
            </div>
            <div class="text-muted text-center icon-text">App Icon</div>
        </div> -->
        <div class="name">
            <div>App name <span class="text-danger">*</span></div>
            <input type="text" [(ngModel)]="name" name="name" placeholder="My First App" maxlength="24">
            <div class="counter text-muted" [ngClass]="{'text-danger': name.length > 24}">{{name?.length}}/24</div>
        </div>
    </div>
</form>

<div class="w-100 btn-wrapper">
    <button class="next-btn" (click)="createProject()">Next</button>
</div>