// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl: 'https://betaauth.likeminds.community',
  versionCode: '25',
  baseUploadImgUrl: 'https://beta.likeminds.community',
  awsS3MediaUrl: 'https://beta-likeminds-media.s3.amazonaws.com',
  platformCode: 'web',
  awsBucket: 'beta-likeminds-media',
  poolId: 'ap-south-1:181963ba-f2db-450b-8199-964a941b38c2',
};
