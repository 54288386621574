/**
 * @class AuthService
 * @description This class contains all the service related to auth
 */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

import { BaseService } from './base.service';
import { SDK_PROJECT } from '../../shared/constants/api.constant';
import { LocalStorageService } from './localstorage.service';
import { STORAGE_KEY } from 'src/app/shared/enums/storage-keys.enum';

@Injectable({
  providedIn: 'root',
})
export class SDKService extends BaseService {
  currentProject$$ = new BehaviorSubject(null);
  currentProjectBranding$$ = new BehaviorSubject(null);
  refresh$$ = new BehaviorSubject(null);

  constructor(
    httpClient: HttpClient,
    private localStorageService: LocalStorageService
  ) {
    super(httpClient);
  }

  createProject(params): Observable<any> {
    return this.post(params, null, SDK_PROJECT);
  }

  saveProject(body): Observable<any> {
    return this.put(body, null, SDK_PROJECT);
  }

  getProject(params?, headers?): Observable<any> {
    return this.get(
      params,
      SDK_PROJECT,
      headers?.api_key ? { 'x-api-key': headers.api_key } : null
    );
  }

  deleteProject(): Observable<any> {
    return this.delete(null, null, SDK_PROJECT);
  }

  setBranding(branding: any) {
    this.currentProjectBranding$$.next({ ...branding });
    this.localStorageService.setSavedState(
      { ...branding },
      STORAGE_KEY.BRANDING
    );

    //branding colors init
    document.body.style.setProperty(
      '--header_colour',
      branding?.advanced?.header_colour
        ? branding?.advanced?.header_colour
        : branding?.basic?.primary_colour
        ? branding?.basic?.primary_colour
        : '#007AFF'
    );
    document.body.style.setProperty(
      '--buttons_icons_colour',
      branding?.advanced?.buttons_icons_colour
        ? branding?.advanced?.buttons_icons_colour
        : branding?.basic?.primary_colour
        ? branding?.basic?.primary_colour
        : undefined
    );
    document.body.style.setProperty(
      '--text_links_colour',
      branding?.advanced?.text_links_colour
        ? branding?.advanced?.text_links_colour
        : branding?.basic?.primary_colour
        ? branding?.basic?.primary_colour
        : undefined
    );
    document.body.style.setProperty(
      '--header_text_colour',
      !branding?.advanced?.header_colour ? '#000000' : '#ffffff'
    );

    if (document.body.style.getPropertyValue('--header_colour') === 'undefined')
      document.body.style.removeProperty('--header_colour');
    if (
      document.body.style.getPropertyValue('--buttons_icons_colour') ===
      'undefined'
    )
      document.body.style.removeProperty('--buttons_icons_colour');
    if (
      document.body.style.getPropertyValue('--text_links_colour') ===
      'undefined'
    )
      document.body.style.removeProperty('--text_links_colour');
  }
}
