export const GENERATE_OTP_API = '/otp/generate';
export const VERIFY_OTP_API = '/otp/verify';
export const LOGIN_API = '/user/login';
export const REFRESH_TOKEN_API = '/user/refresh';
export const LOGOUT_API = '/user/logout';

//sdk APIs
export const SDK_PROJECT = '/sdk/project';
export const COMMUNITY_FETCH_MEMBERS_META = '/community/fetch_members_meta';
export const CHATROOM = '/chatroom';
export const COMMUNITY_MEMBER = '/community/member';
export const CHATROOM_PARTTICIPANTS = '/chatroom/participants';
export const CHATROOM_SETTINGS = '/chatroom/settings';
export const CHATROOM_PIN = '/chatroom/pin';
export const CHATROOM_ENABLE_MEMBER_MESSAGE = '/chatroom/enable_member_message';
export const CHATROOM_AUTO_FOLLOW_MEMBER = '/chatroom/auto_follow_members';
export const MODERATION_RIGHTS = '/moderation/rights';
export const CHATROOM_CHANGE_TYPE = '/chatroom/type';
export const COMMUNITY_MANAGEMENT_TOOL = '/community/management/tool';
export const COMMUNITY_MEMBER_REMOVE = '/community/member/remove';
export const COMMUNITY_REPORT = '/community/report';
export const COMMUNITY_MEMBER_STATE = '/community/member/state';
export const CONVERSATION = '/conversation';
export const COMMUNITY_SETTINGS = '/community/settings';
export const COMMUNITY_NOTIFICATION_SETTINGS =
  '/community/settings/notification/conversation';
export const SETTING_NOTIFICATION_FEED =
  '/community/settings/notification/feed';
export const CHATROOM_SEARCH = '/chatroom/search';
export const COMMUNITY_MANAGER_REMOVE = '/community/manager/remove';
export const COMMUNITY_MEMBER_SEARCH = '/community/member/search';
export const FEED_TOPIC = '/feed/topic';
export const FEED_POST_PENDING = '/feed/post/pending';
export const FEED_POST_OPEN = '/feed/post';
