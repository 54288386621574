<main class="hideMobileView">
  <router-outlet></router-outlet>
</main>
<app-size-detector></app-size-detector>

<div class="showMobileView">
  <div class="mobileView">

    <img src="../assets/images/com_outline.png" alt="default image">
    <h2>
      LikeMinds Dashboard is best viewed on Desktop
    </h2>
  </div>
</div>

