import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  AUTH_PATH,
  PAGE_NOT_FOUND_PATH,
  ROOT_PATH,
} from 'src/app/shared/constants/routes.constants';
import { AuthLoginGuard } from './shared/guards/auth-login.guard';
import { AuthGuard } from './shared/guards/auth.guard';

const routes: Routes = [
  {
    path: AUTH_PATH,
    loadChildren: () =>
      import('./layouts/auth/auth.module').then((m) => m.AuthModule),
    canActivate: [AuthLoginGuard],
  },
  {
    path: ROOT_PATH,
    loadChildren: () =>
      import('./layouts/base-layout/base-layout.module').then(
        (m) => m.BaseLayoutModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    redirectTo: PAGE_NOT_FOUND_PATH,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
