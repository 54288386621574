export const ROOT_PATH = '';
export const PAGE_NOT_FOUND_PATH = '404';
export const UNAUTHORISED_PATH = 'unauthorised';
export const GENERATE_OTP_PATH = 'generate_otp';
export const VERIFY_OTP_PATH = 'verify_otp';
export const LOGIN_PATH = 'login';
export const AUTH_PATH = 'auth';
export const COMMUNITY_PATH = 'community';
export const HOME_PATH = 'home';
export const SETTING_PATH = 'setting';
export const COMMUNITY_FEED_PATH = 'community_feed';
export const EVENT_FEED_PATH = 'event_feed';
export const COMMUNITY_DETAIL_PATH = 'community_detail';
export const JOIN_COMMUNITY_FEED_PATH = 'join_community';
export const COMMUNITY_QUESTION_PATH = 'community_questions';
export const CHATROOM_PATH = 'collabcard';
export const ROUTE_BROWSER_LINK = 'browser?link';
export const ROUTE_BROWSER_URL = 'browser?url';
export const ROUTE_HIGHLIGHT = 'highlight';
export const ROUTE_PAYMENT_DIALOG = 'payment_dialog';
export const ROUTE_MAIL_TO = 'mail?to';

export const routePath = {
  HOME: 'home',
  COMMUNITY: 'community',
  SETTINGS: 'settings',
  CHATROOMS: 'chatrooms',
  MEMBERS: 'members',
  MEMBER_COHORTS: 'membercohorts',
  MODERATIONS: 'moderations',
};

export const settingsRoutesPath = {
  GENERAL: 'general',
  FEED: 'feed',
  FEATURE_AND_SETTINGS: 'featureandsettings',
  ROLES_AND_PERMISSIONS: 'rolesandpermissions',
  NOTIFICATION_SETTINGS: 'notificationsettings',
};
