/**
 * @class ApiInterceptor
 * @description It intercepts all the HTTP requests
 */

import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpEvent,
  HttpRequest,
  HttpHandler,
  HttpHeaders,
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { LocalStorageService } from '../services/localstorage.service';
import { STORAGE_KEY } from '../../shared/enums/storage-keys.enum';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(private localStorageService: LocalStorageService) {}
  intercept(
    httpRequest: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    let BASE_URL = '';
    BASE_URL = environment.baseUrl;

    const isOTPRequest = httpRequest.url.includes('otp');
    const isLoginRequest = httpRequest.url.includes('login');
    const isRefreshRequest = httpRequest.url.includes('refresh');
    const isAssetRequest = httpRequest.url.includes('assets');
    const isReportRequest = httpRequest.url.includes('report');
    const isPostPendingRequest = httpRequest.url.includes('post/pending');
    const isPostRequest = httpRequest.url.includes('feed/post');

    const isAPIProjectRequest =
      (httpRequest.url.includes('project') && httpRequest.method !== 'POST') ||
      httpRequest.url.includes('chatroom') ||
      httpRequest.url.includes('member') ||
      httpRequest.url.includes('tool') ||
      httpRequest.url.includes('report') ||
      httpRequest.url.includes('remove') ||
      httpRequest.url.includes('conversation') ||
      httpRequest.url.includes('settings') ||
      httpRequest.url.includes('feed/topic') ||
      httpRequest.url.includes('post/pending') ||
      httpRequest.url.includes('feed/post') ||
      httpRequest.url.includes('rights');

    const isProjectGETRequest =
      httpRequest.url.includes('project') && httpRequest.method === 'GET';

    const isUploadUriAws = httpRequest.url.includes(
      `${environment.awsS3MediaUrl}`,
    );
    const url =
      `${isAssetRequest || isUploadUriAws ? '' : BASE_URL}` + httpRequest.url;
    httpRequest = httpRequest.clone({ url });

    let headers: HttpHeaders = new HttpHeaders();
    if (!isAssetRequest) {
      headers = new HttpHeaders({
        'x-platform-code': environment.platformCode,
        Accept: 'application/json',
      });
    }

    if (!isOTPRequest && !isLoginRequest && !isRefreshRequest) {
      headers = headers.set(
        'Authorization',
        `Bearer ${this.localStorageService.getSavedState(
          STORAGE_KEY.ACCESS_TOKEN_LTM,
        )?.access_token}`,
      );
    }

    if (isLoginRequest) {
      headers = headers.set(
        'Authorization',
        `Bearer ${this.localStorageService.getSavedState(
          STORAGE_KEY.ACCESS_TOKEN_VTM,
        )?.access_token}`,
      );
    }

    if (isRefreshRequest) {
      headers = headers.set(
        'Authorization',
        `Bearer ${this.localStorageService.getSavedState(
          STORAGE_KEY.REFRESH_TOKEN_RTM,
        )?.refresh_token}`,
      );
    }

    if (!isAssetRequest) {
      headers = headers.append(
        'x-version-code',
        environment.versionCode.toString(),
      );
    }
    if (isReportRequest || isPostPendingRequest || isPostRequest) {
      headers = headers.append('x-accept-version', 'v1');
    }

    if (
      isAPIProjectRequest &&
      this.localStorageService.getSavedState(STORAGE_KEY.OPENED_PROJECT) &&
      !isProjectGETRequest
    ) {
      headers = headers.append(
        'x-api-key',
        this.localStorageService.getSavedState(STORAGE_KEY.OPENED_PROJECT)
          ?.api_key,
      );
    }
    if (isAPIProjectRequest) {
      headers = headers.append('x-platform-type', 'dashboard');
    }

    httpRequest = httpRequest.clone({ headers });

    return next.handle(httpRequest);
  }
}
