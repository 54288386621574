export enum STORAGE_KEY {
    LIKEMINDS_USER = '__likeminds_user__',
    ACCESS = '__access__',
    ACCESS_TOKEN_LTM = '__access_token_LTM__',
    ACCESS_TOKEN_VTM = '__access_token_VTM__',
    REFRESH_TOKEN_RTM = '__refresh_token_RTM__',
    AUTH = '__auth__',
    URL_PATTERN = '__url_pattern__',
    BRANDING = '__branding__',
    OPENED_PROJECT = '__opened_project__'
}
