import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { BaseService } from 'src/app/core/services/base.service';
import {
  COMMUNITY_MANAGEMENT_TOOL,
  COMMUNITY_MANAGER_REMOVE,
  COMMUNITY_MEMBER_STATE,
  COMMUNITY_REPORT,
  CONVERSATION,
  FEED_POST_OPEN,
  FEED_POST_PENDING,
  FEED_TOPIC,
} from 'src/app/shared/constants/api.constant';

@Injectable({
  providedIn: 'root',
})
export class ModerationsService extends BaseService {
  private _modereationOptions$$ = new BehaviorSubject<any>({});
  private _modereationReports$$ = new BehaviorSubject<any>({});
  public feedTopics$$ = new BehaviorSubject<any>([]);
  // public feedTopics$$ = new BehaviorSubject<any>({});
  public updateFeedTopic$$ = new BehaviorSubject<boolean>(false);

  public get moderationOptions$() {
    return this._modereationOptions$$.asObservable();
  }

  public get modereationReports$() {
    return this._modereationReports$$.asObservable();
  }

  moderationsCounts$$ = new BehaviorSubject(null);

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  /**
   * @function getModerationOptions
   * @param params
   * @description This function is for fetch all moderations options.
   */
  getModerationOptions(): void {
    this.get({}, COMMUNITY_MANAGEMENT_TOOL).subscribe((res) => {
      this._modereationOptions$$.next(res);
    });
  }

  /**
   * @function getCommunityReports
   * @param params
   * @description This function is for fetch all moderations reports.
   */
  getCommunityReports(): void {
    this.get({}, COMMUNITY_REPORT).subscribe((resData) => {
      this._modereationReports$$.next(resData);
    });
  }

  /**
   * @function savedTopics
   * @param params
   * @description This function is for fetch all the feed topics.
   */
  savedTopics(params: any, postId: string, type?: number): Observable<any> {
    if (type === 1) {
      return this.put(params, null, `${FEED_POST_OPEN}/${postId}`);
    } else {
      return this.put(params, null, `${FEED_POST_PENDING}/${postId}`);
    }
  }

  /**
   * @function getAllTopics
   * @param params
   * @description This function is for fetch all the feed topics.
   */
  getAllTopics(params: any): Observable<any> {
    return this.get(params, FEED_TOPIC);
  }

  /**
   * @function getReport
   * @param params
   * @description This function is for fetch all moderations reports.
   */
  getReport(params: any): Observable<any> {
    return this.get(params, COMMUNITY_REPORT);
  }

  // const params = new HttpParams()
  //   .set('page', page.toString())
  //   .set('pageSize', pageSize.toString())
  //   .set('filterType', JSON.stringify(filterType))
  //   .set('isClosed', isClosed.toString());
  // headers.append('x-accept-version', 'v1');
  // const headers = new HttpHeaders({
  //   'x-accept-version': 'v1',
  // });
  getReports(params: any): Observable<any> {
    return this.get(params, COMMUNITY_REPORT);
  }

  /**
   * @function approvedReport
   * @param params
   * @description This function is for approve the post.
   */
  approvedReport(params: any): Observable<any> {
    return this.patch(params, null, COMMUNITY_REPORT);
  }

  /**
   * @function deleteCommunityReports
   * @param params
   * @description This function is for fetch all moderations reports.
   */
  deleteCommunityReports(param): Observable<any> {
    return this.delete(param, null, COMMUNITY_REPORT);
  }

  /**
   * @function deleteMsgReport
   * @param params
   * @description This function is for fetch all moderations reports.
   */
  deleteMsgReport(param): Observable<any> {
    return this.delete(param, null, CONVERSATION);
  }

  /**
   * @function getMemberPermissionSettings
   * @param params
   * @description This function is for fetch member permissions settings.
   */
  getMemberPermissionSettings(params): Observable<any> {
    return this.get(params, COMMUNITY_MEMBER_STATE);
  }

  /**
   * @function onRemoveCM
   * @param params
   * @description This function is for fetch member permissions settings.
   */
  onRemoveCM(params): Observable<any> {
    return this.delete(params, null, COMMUNITY_MANAGER_REMOVE);
  }
}
