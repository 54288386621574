<div class="login-tutorial d-flex">
    <img class="logo position-absolute" src="assets/images/svg/logo-gray.svg" alt="LikeMinds" />
    <div id="carouselExampleIndicators" class="carousel slide">
        <!-- <div class="carousel-indicators">
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active"
                aria-current="true" aria-label="Slide 1"></button>
        </div> -->
        <div class="carousel-inner">
            <div class="carousel-item active">
                <div class="slide">
                    <img src="assets/images/svg/tutorial.svg" class="tutorial-img mx-auto mt-3">
                    <h1 class="title">
                        <div class="text-start">
                            <img src="assets/images/svg/left-quote.svg">
                        </div>
                        <div>
                            We're excited to work with LikeMinds to help us build our native community platform.
                            Building a full platform for community is challenging and LikeMinds is pivotal in bringing
                            our community to life by supporting us with a scalable and easily integratable tech stack.
                        </div>
                        <div class="text-end">
                            <img src="assets/images/svg/right-quote.svg">
                        </div>
                    </h1>
                    <div>
                        <hr class="w-50 mx-auto">
                    </div>
                    <div class="sub-title text-center mt-5">
                        <div class="blue">Rohit Ganapathia</div>
                        <div class="mt-1">Product @ Man Matters</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>