<div class="createChatroom">
  <div class="createChatroom__header">
    <div class="createChatroom__header__back cursor-pointer" (click)="close()">
      <img
        src="../../../../../assets/images/svg/dialog_back_icon.svg"
        alt="back"
      />
    </div>
    Remove as CM?
    <div
      class="createChatroom__header__cancel cursor-pointer"
      (click)="close()"
    >
      <img src="../../../../../assets/images/svg/cancel.svg" alt="back" />
    </div>
  </div>
  <div class="createChatroom__body">
    <div class="createChatroom__body__media--text">
      Are you sure you want to remove
      {{ data?.user_reported?.name || data?.memberObj?.name }} as community
      manager?
    </div>

    <div class="createChatroom__body__media mt-4">
      <button type="button" (click)="removeCM()">Confirm</button>
    </div>
  </div>
</div>
