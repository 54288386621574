import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CreateProjectDialogComponent } from 'src/app/shared/dialogs/create-project-dialog/create-project-dialog.component';

@Component({
  selector: 'app-welcome-landing',
  templateUrl: './welcome-landing.component.html',
  styleUrls: ['./welcome-landing.component.scss']
})
export class WelcomeLandingComponent implements OnInit {

  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
  }

  CreateNewProject() {
    this.dialog.open(CreateProjectDialogComponent, { panelClass: ['create-project-dialog'] })
  }

}
