import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
import { UtilsService } from 'src/app/core/services/utils.service';
import { ModerationsService } from '../../../services/moderations.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-remove-cm-dialog',
  templateUrl: './remove-cm-dialog.component.html',
  styleUrls: ['./remove-cm-dialog.component.scss'],
})
export class RemoveCmDialogComponent implements OnInit {
  private destroy$$ = new Subject();
  constructor(
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA)
    public data,
    private moderationsService: ModerationsService,
    private utilsService: UtilsService,
    private router: Router
  ) {}

  ngOnInit(): void {}
  close() {
    this.dialogRef.close();
  }
  remove(action: any) {
    this.dialogRef.close(action);
  }

  removeCM() {
    const params = {
      user_id: this.data?.user_reported?.id | this.data?.memberObj?.id,
    };
    this.moderationsService
      .onRemoveCM(params)
      .pipe(takeUntil(this.destroy$$))
      .subscribe((res) => {
        this.utilsService.onShowSnackBar(
          'Removed as community manager.',
          'close'
        );
        this.dialogRef.close();
        this.reloadCurrentRoute();
      });
  }

  reloadCurrentRoute() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  ngOnDestroy(): void {
    this.destroy$$.next(null);
    this.destroy$$.complete();
  }
}
