<div class="w-100 d-flex justify-content-center container-fluid">
    <div class="card">
        <div class="title">
            Welcome to LikeMinds!
        </div>
        <div class="sub-title">
            Start integrating our tools right away and reap the rewards. We have prepared an easy to follow tutorial for
            you. So, let’s get started!
        </div>
        <img src="assets/images/svg/welcome-landing.svg" alt="">
        <button (click)="CreateNewProject()">
            Add your first app
        </button>
    </div>
</div>