import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';

import { isPlatformBrowser } from '@angular/common';
import _ from 'lodash';
import { SDKService } from './core/services/sdk.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'LikeMinds';
  private destroy$$ = new Subject();

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    public translate: TranslateService,
    private router: Router,
    private sdk: SDKService
  ) {
    translate.addLangs(['en', 'hi']);
    translate.setDefaultLang('en');
  }

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (
        event instanceof NavigationEnd &&
        isPlatformBrowser(this.platformId)
      ) {
      }
    });

    this.sdk.currentProject$$
      .pipe(takeUntil(this.destroy$$))
      .subscribe((res) => {
        if (res) this.sdk.setBranding(res?.community?.branding);
      });
  }

  ngOnDestroy(): void {
    this.destroy$$.next(null);
    this.destroy$$.complete();
  }
}
