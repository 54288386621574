<svg
  width="36"
  height="36"
  viewBox="0 0 36 36"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <circle cx="18" cy="18" r="17.5" fill="white" stroke="#007AFF" />
  <path
    d="M16.7656 24.2109C17.1016 24.2109 17.3672 24.0625 17.5547 23.7812L24.5312 12.8359C24.6719 12.6094 24.7266 12.4375 24.7266 12.2578C24.7266 11.8125 24.4297 11.5156 23.9844 11.5156C23.6719 11.5156 23.4922 11.625 23.2969 11.9297L16.7344 22.375L13.3359 17.9531C13.1328 17.6875 12.9453 17.5781 12.6562 17.5781C12.2031 17.5781 11.8828 17.8906 11.8828 18.3359C11.8828 18.5234 11.9609 18.7188 12.1172 18.9219L15.9453 23.7734C16.1875 24.0703 16.4219 24.2109 16.7656 24.2109Z"
    fill="#007AFF"
  />
</svg>
