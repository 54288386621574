import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseService } from 'src/app/core/services/base.service';
import {
  COMMUNITY_MEMBER,
  COMMUNITY_MEMBER_REMOVE,
  MODERATION_RIGHTS,
} from 'src/app/shared/constants/api.constant';

@Injectable({
  providedIn: 'root',
})
export class MemberService extends BaseService {
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  /**
   * @function permissions
   * @param params
   * @description This function is for fetch all member list.
   */
  permissions(params: any): Observable<any> {
    return this.get(params, MODERATION_RIGHTS);
  }

  /**
   * @function permissionUpdate
   * @param params
   * @description This function is for fetch all member list.
   */
  permissionUpdate(params: any): Observable<any> {
    return this.patch(params, null, MODERATION_RIGHTS);
  }

  /**
   * @function addUser
   * @param params
   * @description This function is for fetch all member list.
   */
  addUser(params: any): Observable<any> {
    return this.post(params, null, COMMUNITY_MEMBER);
  }
  /**
   * @function editUser
   * @param params
   * @description This function is for fetch all member list.
   */
  editUser(params: any): Observable<any> {
    return this.put(params, null, COMMUNITY_MEMBER);
  }

  /**
   * @function removeFromCommunity
   * @param params
   * @description This function is for remove member from community.
   */
  removeFromCommunity(params: any): Observable<any> {
    return this.delete(params, null, COMMUNITY_MEMBER_REMOVE);
  }
}
