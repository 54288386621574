<div class="createChatroom">
  <div class="createChatroom__header">
    Edit member permissions
    <div
      class="createChatroom__header__cancel cursor-pointer"
      (click)="close()"
    >
      <img src="../../../../../assets/images/svg/cancel.svg" alt="back" />
    </div>
  </div>
  <div class="createChatroom__body">
    <div
      class="createChatroom__body__media borderBottom d-flex justify-content-between flex-row"
      *ngFor="let right of rights"
    >
      <label for="chatroomName">{{ right.title }}</label>
      <mat-slide-toggle
        [(ngModel)]="right.is_selected"
        (change)="changeRights(right, $event)"
      ></mat-slide-toggle>
    </div>
    <br />
    <div
      class="createChatroom__body__media d-flex justify-content-between flex-coloum"
    >
      <label for="chatroomName" class="custom-text">Custom title</label>
      <input
        type="text"
        class="form-control form-control-style"
        placeholder="Assign a title to this member e.g. Mentor"
        [(ngModel)]="customTitle"
        (input)="enterCustomTitle($event)"
      />
    </div>

    <div class="createChatroom__body__media mt-2">
      <button
        type="button"
        [disabled]="!changeValue"
        (click)="onPermissionUpdate()"
      >
        Save Changes
      </button>
    </div>
  </div>
</div>
