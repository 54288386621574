import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rounded-check-icon',
  templateUrl: './rounded-check.component.html',
  styleUrls: ['./rounded-check.component.scss']
})
export class RoundedCheckComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
