import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgOtpInputModule } from 'ng-otp-input';
import { components } from './components';
import { pipes } from './pipes';
import { dialogs } from './dialogs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './material.module';
import { icons } from './icon-components';
import { NgxPaginationModule } from 'ngx-pagination';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { ImageCropperModule } from 'ngx-image-cropper';
import { FormatDatePipe } from './pipes/format-date.pipe';
import { ShortNamePipe } from './pipes/short-name.pipe';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxBootstrapSliderModule } from 'ngx-bootstrap-slider';
import { SplitStringPipe } from './pipes/split-string.pipe';
import { ManagementRightsDialogComponent } from './entryComponents/management-rights-dialog/management-rights-dialog.component';
import { entryComponents } from './entryComponents';
import { MemberPermissionDialogComponent } from './entryComponents/member-permission-dialog/member-permission-dialog.component';
import { TruncatePipe } from './pipes/truncate.pipe';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    NgOtpInputModule,
    MaterialModule,
    NgxPaginationModule,
    Ng2SearchPipeModule,
    ImageCropperModule,
    InfiniteScrollModule,
    NgxBootstrapSliderModule,
  ],
  declarations: [
    ...components,
    ...pipes,
    ...dialogs,
    ...icons,
    ...entryComponents,
    FormatDatePipe,
    ShortNamePipe,
    SplitStringPipe,
    ManagementRightsDialogComponent,
    MemberPermissionDialogComponent,
    TruncatePipe,
  ],
  exports: [
    ...components,
    ...pipes,
    ...dialogs,
    ...icons,
    ...entryComponents,
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    NgOtpInputModule,
    MaterialModule,
    NgxPaginationModule,
    Ng2SearchPipeModule,
    ImageCropperModule,
    InfiniteScrollModule,
    NgxBootstrapSliderModule,
  ],
  providers: [],
})
export class SharedModule {}
