import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject, takeUntil } from 'rxjs';
import { LocalStorageService } from 'src/app/core/services/localstorage.service';
import { SDKService } from 'src/app/core/services/sdk.service';
import { RemoveCmDialogComponent } from 'src/app/pages/community/moderations/entryComponents/remove-cm-dialog/remove-cm-dialog.component';
import { MemberService } from 'src/app/pages/community/services/member.service';
import { STORAGE_KEY } from '../../enums/storage-keys.enum';

@Component({
  selector: 'app-management-rights-dialog',
  templateUrl: './management-rights-dialog.component.html',
  styleUrls: ['./management-rights-dialog.component.scss'],
})
export class ManagementRightsDialogComponent implements OnInit {
  private destroy$$ = new Subject();
  community: any;
  project: any;
  rightsParams: any;
  managementRight: any = [];
  isCM: boolean;
  customTitle: any;
  removeCM = false;
  changeValue = false;
  constructor(
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private memberService: MemberService,
    private localStorageService: LocalStorageService,
    private sDKService: SDKService,
    private dialog: MatDialog,
    private matSnackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    if (this.data.user_reported.state === 1) this.isCM = true;
    else this.isCM = false;

    this.sDKService.currentProject$$
      .pipe(takeUntil(this.destroy$$))
      .subscribe((res) => {
        this.project = res;
      });
    this.community = this.localStorageService.getSavedState(
      STORAGE_KEY.OPENED_PROJECT
    );
    this.getPermission();
  }
  close() {
    this.removeCM = false;
    this.dialogRef.close();
  }

  goToPage(memberPage: string) {
    this.dialogRef.close(memberPage);
  }

  getPermission() {
    const params = {
      user_id: this.data.user_reported.id,
      is_cm: true,
    };
    this.memberService
      .permissions(params)
      .pipe(takeUntil(this.destroy$$))
      .subscribe((res) => {
        this.customTitle = res.data.member.custom_title;
        res.data.rights.forEach((data) => {
            this.managementRight.push(data);
          // if (data.state === 0 || data.state === 1) {
          //   this.managementRight.push(data);
          // }
        });
      });

    this.rightsParams = {
      user_id: this.data.user_reported.id,
      is_cm: true,
      rights: this.managementRight,
    };
  }

  changeRights(rights, e) {
    this.changeValue =  true;
    this.managementRight.forEach((ele) => {
      if (ele.title === rights.title) {
        ele.is_selected = e.checked;
      }
    });

    this.rightsParams = {
      user_id: this.data.user_reported.id,
      is_cm: true,
      rights: this.managementRight,
      custom_title: this.customTitle,
    };
  }

  enterCustomTitle(e) { 
    this.changeValue =  true;  
  }

  onPermissionUpdate() {
    if(!this.changeValue) return; 
    this.rightsParams = {
      user_id: this.data.user_reported.id,
      is_cm: true,
      rights: this.managementRight,
      custom_title: this.customTitle,
    };

    this.memberService
      .permissionUpdate(this.rightsParams)
      .pipe(takeUntil(this.destroy$$))
      .subscribe((_) => {
        this.dialogRef.close('management');
      });
  }

  removeCMDialog() {
    this.close();

    this.dialog
      .open(RemoveCmDialogComponent, {
        data: this.data,
        disableClose: true,
      })
      .afterClosed()
      .subscribe((res) => {
        if (!res) return;
        // this.matSnackBar.open('msg', 'close', {
        //   duration: 3000,
        //   panelClass: ['matSnackBar'],
        //   horizontalPosition: 'left',
        // });
      });
  }

  ngOnDestroy(): void {
    this.destroy$$.next(null);
    this.destroy$$.complete();
  }
}
