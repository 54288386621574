import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subject, take, takeUntil } from 'rxjs';
import { LocalStorageService } from 'src/app/core/services/localstorage.service';
import { SDKService } from 'src/app/core/services/sdk.service';
import {
  routePath,
  settingsRoutesPath,
} from '../../constants/routes.constants';
import { STORAGE_KEY } from '../../enums/storage-keys.enum';

@Component({
  selector: 'app-create-project-dialog',
  templateUrl: './create-project-dialog.component.html',
  styleUrls: ['./create-project-dialog.component.scss'],
})
export class CreateProjectDialogComponent implements OnInit {
  name: String = '';
  destroy$$ = new Subject();

  constructor(
    private sdkService: SDKService,
    private router: Router,
    private snackbar: MatSnackBar,
    private localStorageService: LocalStorageService,
    private dialog: MatDialogRef<CreateProjectDialogComponent>
  ) {}

  ngOnInit(): void {
    // this.localStorageService.setSavedState(
    //   { api_key: 'b17cd678-f219-45ae-aade-36d8d2120b4b' },
    //   STORAGE_KEY.OPENED_PROJECT
    // );
  }

  createProject() {
    if (!this.name || this.name.length > 24 || this.name.length === 0) return;

    this.sdkService
      .createProject({ name: this.name })
      .pipe(takeUntil(this.destroy$$))
      .subscribe((res) => {
        if (res.success) {
          this.localStorageService.setSavedState(
            { api_key: res.data.api_key },
            STORAGE_KEY.OPENED_PROJECT
          );
          this.sdkService.refresh$$.next({
            status: true,
            redirect: `${routePath.SETTINGS}/${settingsRoutesPath.GENERAL}`,
          });
          this.dialog.close();
        } else {
          this.snackbar.open(
            `Something Went Wrong! ${res.error_message}`,
            null,
            { duration: 3000 }
          );
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$$.next(null);
    this.destroy$$.complete();
  }
}
